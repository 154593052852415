import React, { useState, useContext } from 'react'
import { Link, graphql } from 'gatsby'
import Amplify, { Auth, API, graphqlOperation } from 'aws-amplify'
import { Connect } from 'aws-amplify-react'
import uuid from 'uuid'
import SEO from '../components/seo'
import HeartUnlike from '../components/heartunlike'
import HeartLike from '../components/heartlike'
import { listPostLikes } from '../graphql/queries'
import { createPostLike, deletePostLike } from '../graphql/mutations'
import styled from 'styled-components'
import ProductForm from '../components/ProductForm'
import SoldOut from '../components/ProductGrid/soldOut'
import ProductReview from '../components/productReview'
import Img from 'gatsby-image'
import awsConfig from '../../aws-exports'
Amplify.configure(awsConfig)

const GridLeft = styled.div`
  grid-area: left;
`

const GridRight = styled.div`
  grid-area: right;
`

const Container = styled.div`
  margin: 0 auto;
`
const ImageSection = styled.div`
  svg {
    position: absolute;
    top: 144px;
    z-index: 100;
  }
`
const ImageContain = styled.div`
  margin: 1em 0;
`

const SizeChart = styled.div`
  margin-top: 3em;
`

const TwoColumnGrid = styled.div`
  display: grid;
  grid-template-columns: 1fr 2rem 1fr;
  grid-template-rows: 1auto;
  grid-template-areas: 'left . right';

  @media screen and (max-width: 800px) {
    display: block;
  }
`

const ProductTitle = styled.h1`
  margin-bottom: 15px;
  margin: 0 0 0.5rem;
  line-height: 1.4;
`

const ProductDescription = styled.div`
  margin-top: 40px;

  ul {
    margin-left: 3em;
  }
`

const SizeChartButton = styled.button`
  background: none;
  outline: none;
  border: 1px solid ${props => props.theme.primarycolor};
  padding: 7px;
  border-radius: 4px;
  width: 120px;
  cursor: pointer;
  color: ${props => props.theme.primarycolor};
  transition: all 0.3s ease 0s;

  &:hover {
    background: ${props => props.theme.primarycolor};
    color: white;
  }
`

const SizeChartOpen = styled.div`
  position: fixed;
  background: white;
  max-width: 700px;
  top: 30%;
  left: 200px;
  height: 40vh;

  button {
    float: right;
  }
`

const Like = styled.button`
  background: none;
  border: none;
  outline: none;
`

class ProductPageTemplate extends React.Component {
  state = {
    isLoggedIn: false,
    like: null,
  }

  componentDidMount = async () => {
    const product = this.props.data.shopifyProduct
    const user = await Auth.currentAuthenticatedUser()
    const { data } = await API.graphql(
      graphqlOperation(listPostLikes, {
        filter: { postId: { eq: product.id } },
      })
    )
    const like = data.listPostLikes.items[0]

    this.setState({ isLoggedIn: !!user, like })
  }

  toggleLike = async () => {
    const product = this.props.data.shopifyProduct

    if (this.state.like) {
      await API.graphql(
        graphqlOperation(deletePostLike, {
          input: { id: this.state.like.id },
        })
      )
      this.setState({ like: null })
    } else {
      const like = {
        postId: product.id,
        id: uuid(),
      }

      await API.graphql(
        graphqlOperation(createPostLike, {
          input: like,
        })
      )
      this.setState({ like })
    }
  }

  render() {
    const product = this.props.data.shopifyProduct
    const { isLoggedIn, likesProduct } = this.state
    const avaiable = product.availableForSale
    return (
      <>
        <SEO title={product.title} description={product.description} />
        <Container>
          <TwoColumnGrid>
            <GridLeft>
              <p>
                <Link to="/"> Home </Link> / {product.title}{' '}
              </p>
              <ImageSection>
                {!avaiable && <SoldOut />}
                {product.images.map(image => (
                  <ImageContain key={image.id}>
                    <Img
                      fluid={image.localFile.childImageSharp.fluid}
                      key={image.id}
                      alt={product.title}
                    />
                  </ImageContain>
                ))}
              </ImageSection>
            </GridLeft>
            <GridRight>
              <ProductTitle>{product.title}</ProductTitle>

              {isLoggedIn && (
                <div>
                  <Like onClick={this.toggleLike}>
                    {this.state.like ? <HeartLike /> : <HeartUnlike />}
                  </Like>
                </div>
              )}

              <ProductForm product={product} />
              <ProductDescription
                dangerouslySetInnerHTML={{ __html: product.descriptionHtml }}
              />
            {/* <ProductReview /> */}

            </GridRight>
          </TwoColumnGrid>

        </Container>
      </>
    )
  }
}

export default ProductPageTemplate

export const query = graphql`
  query($handle: String!) {
    shopifyProduct(handle: { eq: $handle }) {
      id
      title
      handle
      productType
      tags
      description
      descriptionHtml
      shopifyId
      availableForSale
      options {
        id
        name
        values
      }
      variants {
        id
        title
        price
        image {
          localFile {
            childImageSharp {
              fluid(maxWidth: 910) {
                ...GatsbyImageSharpFluid
              }
            }
          }
        }
        availableForSale
        shopifyId
        selectedOptions {
          name
          value
        }
      }
      priceRange {
        minVariantPrice {
          amount
          currencyCode
        }
        maxVariantPrice {
          amount
          currencyCode
        }
      }
      images {
        originalSrc
        id
        localFile {
          childImageSharp {
            fluid(maxWidth: 910) {
              ...GatsbyImageSharpFluid
            }
          }
        }
      }
    }
  }
`
